// composables/useRecaptcha.js
export default function useRecaptcha() {
  const injectScriptRecaptcha = () => {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }

  const deleteScriptRecaptcha = () => {
    const script = document.querySelector(
      'script[src="https://www.google.com/recaptcha/api.js"]'
    )
    if (script) {
      document.body.removeChild(script)
    }
  }

  return {
    injectScriptRecaptcha,
    deleteScriptRecaptcha,
  }
}
